












































































































































import { defineComponent, useContext, ref, nextTick, computed } from '@nuxtjs/composition-api';
import { appStore, userStore } from '@/store';
import { useStore } from '@/compositions/store';
import {
  openFukuri,
  isShowCampfireNewBadge,
  isShowBusinessSuccessionNewBadge,
  isShowIcnextLinkageNewBadge
} from '@/utils';
import NavigationMainButton from '@/components/layout/navigation-main-button.vue';
import { requirePermission } from '@/utils/filter';

export default defineComponent({
  name: 'Footer',
  components: { NavigationMainButton },
  props: {
    showFooterNavi: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup() {
    const {
      showFooter,
      isMobile,
      mobileNavi,
      yappliRegisteredId,
      unreadCmiCount,
      bachatRoomsUnreadCount,
      chatRoomsUnreadCount,
      matchingUnrepliedCount
    } = useStore();

    const loggedIn = ref<boolean>(false);

    const ctx = useContext();
    loggedIn.value = ctx.$auth.loggedIn;

    /**
     * Fukuriの書込権限がない場合はFukuriに遷移する
     */
    const handleFukuri = (): void => {
      if (!requirePermission(['company_setting_coupon_write'])) {
        mobileNavi.value.isShow = false;
        openFukuri();
        return;
      }
      handleMobileNavi('福利厚生');
    };

    /**
     * SPサイズのフッターナビをハンドリングする
     * ナビのコンポーネントはcomponents/layout/footer-navi.vueでcomponents/layout/navigation.vue内に存在する
     * @param title
     */
    const handleMobileNavi = (title: string): void => {
      // 既にクリックされたボタンのナビが開いていれば閉じるだけ
      if (mobileNavi.value.isShow && mobileNavi.value.title === title) {
        mobileNavi.value.isShow = false;
        return;
      }
      // 既に開いているナビを閉じてクリックされたナビを開く
      mobileNavi.value.isShow = false;
      nextTick(() => {
        mobileNavi.value.title = title;
        mobileNavi.value.isShow = true;
      });
    };

    /**
     * 指定されたパスに同期的遷移を行う
     * @param path パス
     */
    const handleMobileToPageByHref = (path: string): void => {
      window.location.href = path;
    };

    const showCampfireNewBadge = ref<boolean>(
      isShowCampfireNewBadge(
        ctx.$formatDateTime(new Date().toString(), 'YYYY-MM-DD HH:mm:ss')
      )
    );

    // いつ事業承継を確認したかのフラグを追加(存在していたらnewバッジを出すか出さないかのを確認)
    const showBusinessSuccessionNewBadge = ref<boolean>(
      isShowBusinessSuccessionNewBadge(
        ctx.$formatDateTime(new Date().toString(), 'YYYY-MM-DD HH:mm:ss')
      )
    );

    // いつ勤怠連携機能を確認したかのフラグを追加(存在していたらnewバッジを出すか出さないかのを確認)
    const showAttendanceLinkageNewBadge = ref<boolean>(
      isShowIcnextLinkageNewBadge(
        ctx.$formatDateTime(new Date().toString(), 'YYYY-MM-DD HH:mm:ss')
      )
    );

    const isShowServiceBadge = computed(() =>
      showCampfireNewBadge ||
      showBusinessSuccessionNewBadge ||
      showAttendanceLinkageNewBadge
    );

    /**
     * 未読の総カウントを取得する
     */
    const totalUnreadCount = computed(() => {
      let total = bachatRoomsUnreadCount.value as number + chatRoomsUnreadCount.value as number;
      // 企業オーナーまたはビジネスユーザーの場合はご案内の未読数を加算する
      const userType = userStore.context.state.loginUser.user_type;
      if (userType === 'company_owner' || userType === 'company_user' || userType === 'partner_company_owner') {
        total += unreadCmiCount.value as number;
      }
      return total;
    });

    /**
     * マッチング未読の総カウントを取得する
     */
    const totalMatchingCount = computed(() => {
      let total = 0;
      // オーナーの場合はご案内の未読数を加算する
      if (userStore.context.state.loginUser.user_type ===
          'company_owner' ||
          userStore.context.state.loginUser.user_type ===
          'partner_company_owner' ||
          userStore.context.state.loginUser.user_type ===
          'company_user'
      ) {
        total += matchingUnrepliedCount.value as number;
      }
      return total;
    });

    return {
      loggedIn,
      appStore,
      userStore,
      showFooter,
      isMobile,
      mobileNavi,
      handleFukuri,
      yappliRegisteredId,
      handleMobileNavi,
      handleMobileToPageByHref,
      unreadCmiCount,
      bachatRoomsUnreadCount,
      chatRoomsUnreadCount,
      isShowServiceBadge,
      totalUnreadCount,
      matchingUnrepliedCount,
      totalMatchingCount
    };
  }
});
