import Middleware from './middleware'
import { Auth, authMiddleware, ExpiredAuthSessionError } from '~auth/runtime'

// Active schemes
import { Oauth2Scheme } from '~auth/runtime'

Middleware.auth = authMiddleware

export default function (ctx, inject) {
  // Options
  const options = {
  "resetOnError": false,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": false,
  "watchLoggedIn": true,
  "redirect": {
    "login": "/login",
    "logout": "/",
    "home": "/",
    "callback": "/callback"
  },
  "cookie": false,
  "localStorage": {
    "prefix": "auth."
  },
  "defaultStrategy": "bamidp"
}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // bamidp
  $auth.registerStrategy('bamidp', new Oauth2Scheme($auth, {
  "token": {
    "property": "id_token",
    "type": "Bearer",
    "maxAge": 1800
  },
  "refreshToken": {
    "property": "refresh_token",
    "maxAge": 2592000
  },
  "responseType": "code",
  "grantType": "authorization_code",
  "clientId": "big-advance",
  "scope": [
    "openid"
  ],
  "endpoints": {
    "authorization": "https://bamanager2-idp.test.kkdv.tech/oidc/bank/auth",
    "token": "https://bamanager2-idp.test.kkdv.tech/oidc/bank/token",
    "logout": "https://bamanager2-idp.test.kkdv.tech/oidc/bank/auth/logout"
  },
  "name": "bamidp"
}))

  // bamidp_bank0182
  $auth.registerStrategy('bamidp_bank0182', new Oauth2Scheme($auth, {
  "token": {
    "property": "id_token",
    "type": "Bearer",
    "maxAge": 1800
  },
  "refreshToken": {
    "property": "refresh_token",
    "maxAge": 2592000
  },
  "responseType": "code",
  "grantType": "authorization_code",
  "clientId": "big-advance",
  "scope": [
    "openid"
  ],
  "endpoints": {
    "authorization": "https://idp.eggshell.platform.test.kkdv.tech/oidc/bank0182/auth",
    "token": "https://idp.eggshell.platform.test.kkdv.tech/oidc/bank0182/token",
    "logout": "https://idp.eggshell.platform.test.kkdv.tech/oidc/bank0182/logout"
  },
  "name": "bamidp_bank0182"
}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return
      }

      console.error('[ERROR] [AUTH]', error)
    }
  })
}
